
import ActionTypes from '../actions/actionTypes' ;

const INITIAL_STATE = {
    isAuthenticated : true ,
    access_token : '' ,
    user_id : '',
    userdata : {},
    error: {},
    message : "IDLE" ,
}

export default ( state={INITIAL_STATE} , action={} ) => {
    switch(action.type) {
        case (ActionTypes.SignInUser):
            //console.log(action.payload.data.user._id);
            return ({
                ...state,
                access_token: action.payload.data.accessToken,
                isAuthenticated: true,
                user_id: action.payload.id,
                user_email : action.payload.email,
            });
        
        case ActionTypes.SigninUserError:
            return ({
                ...state,
                error: action.payload
            });

        case ActionTypes.RefreshToken:
            return ({
                ...state,
                isAuthenticated: true,
                access_token: action.payload.token,
            });

        case ActionTypes.RefreshTokenError:
            return ({
                ...state,
                isAuthenticated: false,
                error: action.payload,
                access_token: '',
                message : "ERROR"
            });
        
        case (ActionTypes.ChangePassword):
            return ({
                ...state,
                message : "SUCCESS"
            });
        
        case ActionTypes.ChangePasswordError:
            return ({
                ...state,
                error: action.payload,
                message : "ERROR"
            });
        case ActionTypes.ConfirmChangePassword:
            return ({
                ...state,
                message : "IDLE"
            });
        default :
            return state ;
    }
}