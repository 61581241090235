
import React from 'react';

// Suspense
import { Fragment, Suspense, lazy } from 'react';

// Theme
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from './utils/theme';

// Global Style
// import GlobalStyles from "./utils/globalstyles";

// Language
import { LanguageProvider } from "./utils/Language";

// Pace
// import Pace from "./utils/Pace";

// Store
import { Provider } from 'react-redux';
import store from './redux';

// Router
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import { LocationContextProvider } from 'context/LocationContext';

const MainComponent = lazy(() => import('./components/Main'));

const App = () => {
  function getLibrary(provider) {
    return new Web3(provider);
  }
  return (
    <BrowserRouter>
      <LocationContextProvider>
        <LanguageProvider>
          <Provider store={store}>
            <Web3ReactProvider getLibrary={getLibrary}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <Suspense fallback={<Fragment />} >
                  <Routes>
                    <Route path="*" element={<MainComponent />} />
                  </Routes>
                </Suspense>
              </ThemeProvider>
            </Web3ReactProvider>
          </Provider>
        </LanguageProvider>
      </LocationContextProvider>
    </BrowserRouter>
  );
}

export default App;
