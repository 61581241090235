import { LinearProgress, Typography } from "@mui/material"
import axios from "axios"
import { AllowedAccessIPs } from "constants/index"
import React, { useEffect } from "react"

export const LocationContext = React.createContext({})

export const LocationContextProvider = ({ children }) => {
    const [location, setLocation] = React.useState("")
    const [pageLoading, setPageLoading] = React.useState(true)

    useEffect(() => {
        const fetchLocationData = async () => {
            try {
                await axios.get('https://geolocation-db.com/json/')
                    .then(res => {
                        setLocation(res.data.country_code)
                        if (AllowedAccessIPs.includes(res.data.IPv4)) {
                            setPageLoading(false)
                        }
                    })

            }
            catch {

            }
        }
        fetchLocationData()

    }, [])

    return (
        <LocationContext.Provider value={{
            location,
            setLocation
        }}>
            {children}
        </LocationContext.Provider>
    )
}