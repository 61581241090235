const { Network } = require('alchemy-sdk')

export const DefaultLocale = 'EN';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const CALADEX_ETH_ADDRESS = process.env.REACT_APP_CALADEX_ETH_ADDRESS;
export const CALADEX_POLYGON_ADDRESS = process.env.REACT_APP_CALADEX_POLYGON_ADDRESS;
export const COINGECKO_API_KEY = process.env.REACT_APP_COINGECKO_API_KEY;
export const ALCHEMY_API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

export const ALCHEMY_NETWORKS = {
    '0x1': Network.ETH_MAINNET,
    '0x89': Network.MATIC_MAINNET,
    '0x38': Network.BNB_MAINNET
}

export const CALADEX_ADDRESSES = {
    '0x1': process.env.REACT_APP_CALADEX_ETH_ADDRESS,
    '0x89': process.env.REACT_APP_CALADEX_POLYGON_ADDRESS,
    '0x61': process.env.REACT_APP_CALADEX_BSCTEST_ADDRESS,
    '0x38': process.env.REACT_APP_CALADEX_BSC_ADDRESS,
}

export const FARMING_ADDRESSES = {
    '0x1': process.env.REACT_APP_ETH_FARMING_ADDR,
    '0x61': process.env.REACT_APP_BSCTEST_FARMING_ADDR,
    '0x89': process.env.REACT_APP_POLYGON_FARMING_ADDR,
    '0x38': process.env.REACT_APP_BSC_FARMING_ADDR,
    '0x13881': process.env.REACT_APP_POLYGON_TEST_FARMING_ADDR
}

// each block validation time for chains

export const BLOCK_VERIFY_TIMES = {
    '0x1': 12,
    '0x61': 3,
    '0x89': 3,
    '0x38': 2,
    '0x13881': 2
}

// site key for recaptcha
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY

export const USER_ROLES = {
    USER: 'user',
    STAFF: 'staff',
    ADMIN: 'admin',
}

export const USER_STATUS = {
    PENDING: "pending",
    SUBMITTED: "submitted",
    REVIEWING: "reviewing",
    DENIED: "denied",
    APPROVED: "approved",
    VERIFYING: "verifying",
    VERIFIED: "verified",
    BLOCKED: "blocked",
}

export const USER_STATUS_RANK = {
    [USER_STATUS.BLOCKED]: 0,
    [USER_STATUS.DENIED]: 1,
    [USER_STATUS.PENDING]: 2,
    [USER_STATUS.SUBMITTED]: 3,
    [USER_STATUS.REVIEWING]: 4,
    [USER_STATUS.APPROVED]: 5,
    [USER_STATUS.VERIFYING]: 6,
    [USER_STATUS.VERIFIED]: 7
}